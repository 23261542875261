<template>
    <div>
      <el-button type="success" :disabled="$store.state.userRoles !== 'ADMIN'" @click="showaddPurchaseModal" icon="el-icon-user"
        round>Add Purchase</el-button>
      <article>
        <section>
          <div class="">
            <el-dialog :title="`Add Purchase`" :visible.sync="addPurchaseModal" :destroy-on-close="true"
              :before-close="handleClose" style="text-align: left">
              <div v-loading="submitting">
                <el-form :model="purchaseForm" :rules="rules" label-position="top" ref="purchaseForm" class="demo-purchaseForm2">
                  <div class="row">
                    <div class="col-md-12">
                      {{ product }}
                      <el-form-item label="Product" prop="productID">
                        <el-select v-model="purchaseForm.productID" @change="getOneProduct(purchaseForm.productID)" placeholder="Select">
                          <el-option
                            v-for="item in products"
                            :key="item.ProductID"
                            :label="item.ProductName"
                            :value="item.ProductID">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-12">
                      <el-form-item label="Supplier" prop="supplierID">
                        <el-select v-model="purchaseForm.supplierID" placeholder="Select">
                          <el-option
                            v-for="item in suppliers"
                            :key="item.supplierID"
                            :label="item.supplierName"
                            :value="item.supplierID">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-12">
                      {{ Number(product.quantityAvailable) + Number(purchaseForm.quantityPurchased) }}
                      <el-form-item label="Quantity Purchased" prop="quantityPurchased">
                        <el-input v-model="purchaseForm.quantityPurchased" @keyup.enter.native="addPurchase('purchaseForm')"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-12">
                      <el-form-item label="Unit Price" prop="unitPrice">
                        <el-input v-model="purchaseForm.unitPrice" @keyup.enter.native="addPurchase('purchaseForm')"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button type="success" round @click="addPurchase('purchaseForm')">Add Purchase
                      </el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>Design by <a href="https://ovalspace.co"
                        style="color: black;"><strong>Ovalspace</strong></a></small>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
  
        addPurchaseModal: false,
        editWarehouseModal: false,
        value: "",
        loadingError: false,
        submitting: false,

        products: [],
        suppliers:[],
        product: {},

        purchaseForm: {
            productID: "",
            quantityPurchased: "",
            supplierID: "",
            unitPrice: ""
        },
  
        rules: {
          productID: [ 
            { required: true, message: "Product is required", trigger: "blur", },
          ],
          quantityPurchased: [
            { required: true, message: "Quantity Purchased is required", trigger: "blur" },
          ],
          supplierID: [
            { required: true, message: "Supplier is required", trigger: "blur" },
          ],
          unitPrice: [
            { required: true, message: "Unit Price is required", trigger: "blur" },
          ]
        },
      };
    },
  
    methods: {
      handleClose(done) {
        done();
      },
  
      showaddPurchaseModal() {
        this.addPurchaseModal = true;
        this.getSuppliers();
        this.getProducts();
        this.purchaseForm.productID = "";
        this.purchaseForm.quantityPurchased = "";
        this.purchaseForm.unitPrice = "";
      },
  
      async addPurchase(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.post(`purchases`, {
                productID: this.purchaseForm.productID,
                quantityPurchased: this.purchaseForm.quantityPurchased,
                supplierID: this.purchaseForm.supplierID,
                unitPrice: this.purchaseForm.unitPrice,
                addedBy: this.$store.state.userId,
                company_id: this.$store.state.company.company_id,
                updatedQuantity: Number(this.product.quantityAvailable) + Number(this.purchaseForm.quantityPurchased)
              });
              if (
                response.data.success &&
                response.data.message == "PURCHASE_CREATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Purchase added successfully",
                  type: "success",
                });
                this.addPurchaseModal = false;
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to add Purchase",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },

      
      async getSuppliers() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          let request = await this.$http.get(`suppliers/${this.$store.state.company.company_id}`);
          if (request.data.success) {
            this.suppliers = request.data.suppliers;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch Suppliers now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },

      
  
      async getProducts() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(
            `products/get-products/${this.$store.state.company.company_id}`
          );
          if (request.data.success) {
            this.products = request.data.products;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch products now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async getOneProduct(productID) {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(
            `products/${productID}`
          );
          if (request.data.success) {
            this.product = request.data.product;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch products now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      }
      
    },
  };
  </script>
  
  <style>
    .el-select {
      width: 100%;
    }
  </style>